<template>
	<header>
		<nav class="main fix-on-scroll" :class="[page && page.headingImage ? '' : 'no-slider', { active: showMenu }]">
			<div class="row">
				<div class="columns column12 flex-row">
					<div v-if="showMenu" class="menu-button button" @click="hideMenu">
						<font-awesome-icon icon="fa-light fa-times" size="1x" />
						<span>{{ $t('close') }}</span>
					</div>
					<div v-else class="menu-button button" @click="toggleMenu">
						<font-awesome-icon icon="fa-light fa-bars" size="1x" />
						<span>{{ $t('menu') }}</span>
					</div>
					<nuxt-link
						class="logo"
						aria-label="Back to homepage"
						:to="{ path: localePath('/', locale), hash: route.name.startsWith('index') && '#top' }"
					/>
					<nuxt-link
						class="active-nav-logo"
						aria-label="Back to homepage"
						:to="{ path: localePath('/', locale), hash: route.name.startsWith('index') && '#top' }"
						@click="hideMenu"
					/>
					<div class="nav-buttons">
						<lang-select :items="page.langmenu" />
						<a :href="`mailto:${defaults[locale].website.email}`">
							<font-awesome-icon icon="fa-light fa-envelope" size="1x" />
						</a>
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<font-awesome-icon icon="fa-light fa-phone" size="1x" />
						</a>
						<a
							v-if="defaults[locale].website.whatsapp"
							:href="`https://wa.me/${$phoneRaw(defaults[locale].website.whatsapp)}`"
							target="_blank"
						>
							<font-awesome-icon icon="fa-brands fa-whatsapp" size="1x" />
						</a>
						<a
							class="button button-cta reserve-now right"
							aria-label="Reserve now"
							target="_blank"
							:href="`https://booking.eu.guestline.app/DELPHI/availability?hotel=DELPHI&lng=${locale}`"
						>
							<font-awesome-icon icon="fa-light fa-calendar-days" size="1x" />
							<span>{{ defaults[locale].website.book }}</span>
						</a>
					</div>
				</div>
			</div>
			<div class="nav-container" :class="{ active: showMenu }">
				<div class="row">
					<ul class="main-nav">
						<li
							v-for="item in topmenu[locale]"
							:key="item.filename"
							:class="{ 'has-dropdown': item.submenu.length }"
						>
							<nuxt-link :exact="item.slug === 'index'" :to="`${item.filename}`" @click="hideMenu">
								{{ item.header }}
							</nuxt-link>
							<submenu
								v-if="item.submenu.length"
								:hide-all="!showMenu"
								:item="item"
								:subitems="item.submenu"
								@hide-menu="hideMenu"
							/>
						</li>
					</ul>
					<div class="nav-contact">
						<div>
							<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
								<font-awesome-icon icon="fa-light fa-phone" size="1x" />
							</a>
							<a
								v-if="defaults[locale].website.whatsapp"
								:href="`https://wa.me/${$phoneRaw(defaults[locale].website.whatsapp)}`"
								target="_blank"
							>
								<font-awesome-icon icon="fa-brands fa-whatsapp" size="1x" />
							</a>
							<a :href="`mailto:${defaults[locale].website.email}`">
								<font-awesome-icon icon="fa-light fa-envelope" size="1x" />
							</a>
						</div>
						<div class="vertical-border" />
						<social-media :socials="socials" />
						<div v-if="page" class="lang-nav">
							<div v-for="item in page.langmenu" :key="`${item.filename}-${item.language}`">
								<nuxt-link :class="item.language" :to="`${item.filename}`" @click="hideMenu">
									<span>{{ $t(item.language) }}</span>
								</nuxt-link>
								<div class="vertical-border" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
	</header>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, socials, fetchDefaults } = useDefaults();
const { locale } = useI18n();
const route = useRoute();

await fetchPage();
await fetchDefaults();

const showMenu = ref(false);

const { data: topmenu } = await useWebsiteFetch('topmenu');

const toggleMenu = () => {
	showMenu.value = !showMenu.value;
	document.body.classList.add('locked');
};

const hideMenu = () => {
	showMenu.value = false;
	document.body.classList.remove('locked');
};
</script>

<style lang="scss" scoped>
.logo {
	background: url('~/assets/images/logo-the-delphi-white-visual.png') no-repeat center center;
	background-size: 21px;
	width: 21px;
	height: 56px;
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	opacity: 0;
}

nav.main {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	padding: 30px 0;
	transition: background 0.3s ease-in-out;
	overflow: visible;
}

nav .row {
	overflow: visible;
	z-index: 102;
	position: relative;
}

nav.main.scroll,
nav.main.no-slider {
	padding: 18px 0;
	background: rgba(51 84 56 / 50%);
	backdrop-filter: blur(34px);
	overflow: visible;

	&.active {
		backdrop-filter: none;
	}
}

.reserve-now {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;

	svg {
		margin: 0 12px 2px 0;
		display: none;
	}
}

.menu-button {
	background: none;
	padding: 0;
	margin: 0;
	font-size: 22px;
	font-weight: 300;
	cursor: pointer;
	text-align: left;
	animation: fade-from-left 0.7s ease;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	opacity: 0.85;
	text-transform: lowercase;

	svg {
		font-size: 34px;
		margin: 0 5px 0 0;
	}

	span {
		margin: -3px 0 3px;
	}

	&:hover {
		opacity: 1;
		background: none;
	}
}

nav .flex-row,
.nav-buttons {
	justify-content: space-between;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.nav-buttons {
	gap: 22px;
	color: #fff;
	animation: fade-from-right 0.7s ease;
	background: rgba(51 84 56 / 50%);
	backdrop-filter: blur(34px);
	padding: 12px;
	transition: padding 0.3s ease-in-out;

	.lang-select {
		border-right: 1px solid #fff;
	}

	a {
		color: #fff;
		text-decoration: none;
		margin: 0;
		opacity: 0.85;

		&.button,
		&:hover {
			opacity: 1;
		}
	}
}

.scroll,
.no-slider {
	.nav-buttons {
		padding: 0;
		background: none;
		backdrop-filter: none;
	}

	.logo {
		opacity: 1;
	}
}

.active-nav-logo {
	background: url('~/assets/images/logo-the-delphi-active-nav-white.png') no-repeat center center;
	background-size: 199px;
	width: 199px;
	height: 106px;
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin: 0 auto;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
}

.active {
	.nav-buttons {
		padding: 0;
		background: none;
		backdrop-filter: none;
	}

	.active-nav-logo {
		z-index: 1;
		opacity: 1;
	}

	.logo {
		opacity: 0;
	}
}

.nav-container {
	background: rgba(51 84 56 / 80%);
	backdrop-filter: blur(34px);
	padding: 30px 50px;
	position: fixed;
	top: 0;
	left: 0;
	padding-top: 80px;
	z-index: -1;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: all 0.4s ease-in-out;
	transform: translate(0, 100%) matrix(0, 0, 0, 0, 0, 0);

	.row {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
}

.nav-container.active {
	transform: matrix(1, 0, 0, 1, 0, 0);
	opacity: 1;
	z-index: 101;
}

.lang-nav {
	display: none !important;
	list-style: none;
	text-align: left;
	line-height: 26px;
	padding-top: 40px;

	a {
		color: #fff;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
		font-size: 16px !important;
		gap: 3px;
		margin: 0 !important;

		&.router-link-exact-active,
		&:hover {
			color: var(--cta-color);
		}
	}

	.vertical-border {
		height: 16px;
		margin: 0 0 0 10px;
	}

	div:last-child {
		.vertical-border {
			display: none;
		}
	}

	img {
		max-width: 16px;
		float: left;
		margin: 0 5px 0 0;
	}
}

.mobile-lang-nav {
	margin: 40px 0 0;
	font-size: 14px;
	display: none;
}

.mobile-lang-nav img {
	max-width: 16px;
	margin: 0 10px 0 0;
}

.main-nav .mobile-lang-nav a {
	width: 100%;
	display: block;
	font-size: 16px;
	line-height: 28px;
}

/* Fullscreen Nav */
.main-nav {
	list-style: none;
	min-width: 25%;
	text-align: center;
	padding-top: 100px;

	li {
		margin: 10px 0;
	}

	a {
		color: #fff;
		font-size: 28px;
		line-height: 54px;
		font-weight: 100;
		font-family: var(--heading-font-family);
		text-transform: uppercase;

		&:hover,
		&.active {
			color: var(--cta-color);
		}
	}
}

.nav-contact {
	align-self: flex-end;
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: center;
	gap: 15px;
	padding: 20px 0 0;
	margin: 40px 0 0;

	svg {
		margin-right: 5px;
	}

	div {
		display: flex;
		align-items: center;
		place-content: center flex-start;
	}

	.vertical-border {
		height: 25px;
		width: 1px;
		background: #fff;
	}

	.socials {
		margin: 0;
	}

	a {
		color: #fff;
		display: block;
		font-weight: 300;
		font-size: 22px;
		margin: 3px 6px;
	}
}

.close-wrapper {
	align-self: flex-start;
}

.nav-close {
	color: #fff;
	cursor: pointer;
	font-size: 54px;
	padding: 0 0 0 12px;
}

/* Breadcrumbs */
.breadcrumbs ul {
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	margin: 0 0 75px;
}

.breadcrumbs li {
	margin: 0 10px 0 0;
}

.breadcrumbs i {
	font-size: 11px;
}

.breadcrumbs a {
	color: var(--body-color);
}

.breadcrumbs a.active {
	color: var(--cta-color);
}

@media (max-height: 740px) {
	.nav-container {
		overflow-y: scroll;
	}

	.lang-nav {
		position: relative;
		bottom: auto;
		left: auto;
		z-index: 1;
	}

	.main-nav {
		z-index: 2;
		position: relative;
	}
}

@media (max-width: 920px) {
	.reserve-now {
		padding: 0;

		svg {
			margin: 0;
			display: block;
		}
	}

	.nav-buttons .lang-select {
		display: none;
	}

	.menu-button {
		justify-content: center;
	}

	.lang-nav {
		display: flex !important;
		flex-flow: row wrap;
		justify-content: center !important;
		align-items: center !important;
		gap: 5px 10px;
		width: 100%;

		a {
			font-family: var(--body-font-family);
			text-transform: none;
		}
	}

	.close-wrapper {
		align-self: flex-end;
	}
}

@media (max-width: 800px) {
	.lang-nav {
		position: relative;
		bottom: auto;
		left: auto;
		right: auto;
		float: left;
		margin: 0 auto;
		padding: 10px 0 0;
		border-top: 1px solid #fff;
		width: auto;
	}

	.main-nav {
		a {
			font-size: 22px;
			line-height: 44px;
		}
	}

	.active-nav-logo {
		background: url('~/assets/images/logo-the-delphi-active-nav-white-small.png') no-repeat center center;
		background-size: 120px;
		width: 120px;
		height: 64px;
	}
}

@media (max-width: 600px) {
	.nav-container {
		padding: 10px 25px 25px;
	}

	.main-nav,
	.contact-info,
	.lang-nav {
		width: 100%;
	}

	nav.main {
		.flex-row {
			padding: 0;
		}
	}

	nav.main.scroll,
	nav.main.no-slider {
		padding: 18px 6px;
	}

	.contact-info {
		margin: 25px auto 0;
	}

	.lang-nav {
		margin-top: 0;
	}

	nav .buttons .button {
		svg {
			margin-right: 0;
			font-size: 16px;
		}

		span {
			display: none;
		}
	}
}
</style>
